jQuery( document ).ready(function($) {

//Slider Header
$('.carousel-item:first').addClass('active');
$('.carousel-indicators-numbers li:first-child').addClass('active');

$('.rw-mobile-toggle').click(function(){
//$(this).toggleClass('active');
$(this).addClass('toggled');
$('.rw-mobile-navigation').addClass('active');
$('.rw-mobile-navigation').removeClass('action');
$('.rw-close-btn').addClass('active');
});
$('.rw-close-btn').click(function(){
$(this).removeClass('active');
$('.rw-mobile-navigation').removeClass('active');
$('.rw-mobile-toggle').removeClass('toggled');
$('.rw-mobile-navigation').addClass('action');
});

// Search Now Button Home Page

$('.rw-hero-search .wpjb-submit').val('Search Now')


// Apply Now Buuton

$('#input_3_5').before('<i class="fas fa-paperclip"></i> ');
// Blog Load More

$('.rw-loop .alm-load-more-btn').html('<i class="far fa-plus"></i>&nbsp;Load more');

// Need Staff Page

$('.rw-select').append('<i class="fas fa-sort-down"></i>');

$('.ginput_container_select').append('<i class="fas fa-sort-down"></i>');


//About Page
$(".rw-testimonial-inner").owlCarousel({
margin:15,
loop:true,
rewind: true,
items:1,
nav: false,
center: true,
autoplay: true,
animateOut: 'fadeOut',
});

//Works Page
$(".rw-tech").owlCarousel({
margin:15,
loop:true,
rewind: true,
items:1,
nav: false,
center: true,
autoplay: true,
autoplayHoverPause:true,
responsive : {
    // breakpoint from 0 up
    0 : {
        items : 1,
        nav: true,
    },
    // breakpoint from 480 up
    480 : {
        items : 1,
        center: false,
        nav: true,
    },
    // breakpoint from 568 up
    568 : {
        items : 3,
        center: false,
        nav: true,
    },
    // breakpoint from 768 up
    768 : {
        items : 4,
        center: false,
    },
    // breakpoint from 768 up
    1000 : {
        items : 3,
    },
},
});


//Works Page Testimonial
$(".rw-testimonial-carousel").owlCarousel({
margin:15,
loop:true,
rewind: false,
items:1,
nav: false,
center: true,
autoplay: true,
autoplayHoverPause:true,
});




$('button.owl-next').html('<i class="fas fa-arrow-right"></i>');
$('button.owl-prev').html('<i class="fas fa-arrow-left"></i>');


$('#wpfront-scroll-top-container').html('<div class="rw-btp"><i class="far fa-angle-up"></i></div>');


$('.navbar-light .navbar-nav .nav-item .nav-link').append('<div class="hover-link"></div>');



// Life Coaching Section
$('.rw-team:first-child').css('display','block');


$('a.life-link').on('click', function(){
var target = $(this).attr('rel');
$("#"+target).show().siblings("div").hide();
});

$('.rw-team-image:first-child').addClass('active');

$(".rw-user").on('click', function(){
$(this).siblings().removeClass('active');
$(this).addClass('active')
});

//User Name

$('.author-link').on('click', function(){
  var name = $(this).attr('name');

  $('.rw-user-name').html('Showing all articles written by ' + name);
});


//File Upload Button

$('.ginput_container_fileupload').append('<button class="file-btn">Browse</button>');

});


// Blog page category filter

filterSelection("all")
function filterSelection(c) {
  var x, i;
  x = document.getElementsByClassName("filterDiv");
  if (c == "all") c = "";
  // Add the "show" class (display:block) to the filtered elements, and remove the "show" class from the elements that are not selected
  for (i = 0; i < x.length; i++) {
    w3RemoveClass(x[i], "show_2");
    if (x[i].className.indexOf(c) > -1) w3AddClass(x[i], "show_2");
  }
}

// Show filtered elements
function w3AddClass(element, name) {
  var i, arr1, arr2;
  arr1 = element.className.split(" ");
  arr2 = name.split(" ");
  for (i = 0; i < arr2.length; i++) {
    if (arr1.indexOf(arr2[i]) == -1) {
      element.className += " " + arr2[i];
    }
  }
}

// Hide elements that are not selected
function w3RemoveClass(element, name) {
  var i, arr1, arr2;
  arr1 = element.className.split(" ");
  arr2 = name.split(" ");
  for (i = 0; i < arr2.length; i++) {
    while (arr1.indexOf(arr2[i]) > -1) {
      arr1.splice(arr1.indexOf(arr2[i]), 1); 
    }
  }
  element.className = arr1.join(" ");
}
